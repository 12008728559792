export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"
export const TOGGLE_NAVIGATION = "TOGGIE_NAVIGATION";

export const SET_SCREEN = 'SET_SCREEN';

export const INVALIDATE_WEATHER = 'INVALIDATE_WEATHER'
export const FETCH_WEATHER_REQUEST = 'FETCH_WEATHER_REQUEST'
export const FETCH_WEATHER_FAILURE = 'FETCH_WEATHER_FAILURE'
export const FETCH_WEATHER_SUCCESS = 'FETCH_WEATHER_SUCCESS'
export const SET_WEATHER = 'SET_WEATHER'


export const weatherConstants = {
    INVALIDATE_WEATHER : 'INVALIDATE_WEATHER',
    FETCH_WEATHER_REQUEST : 'FETCH_WEATHER_REQUEST',
    FETCH_WEATHER_FAILURE : 'FETCH_WEATHER_FAILURE',
    FETCH_WEATHER_SUCCESS : 'FETCH_WEATHER_SUCCESS',
    SET_WEATHER : 'SET_WEATHER',
}