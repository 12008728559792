import React from 'react'
import { Layout } from 'antd'

import '../../App.css';
import './style.css'

const {Footer} = Layout

function FooterComp(){

    return(
        <Footer style={{ textAlign: 'center' }}>Made with love from William ❤️</Footer>
    )
}

export default FooterComp
