import React from 'react'

import '../../App.css';
import './style.css'

function NotFoundPage(){

    return(
        <div>
            Not Found
        </div>
    )

}

export default NotFoundPage